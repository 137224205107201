<template>
  <div class="filters">
    <div class="filter">
      <div class="title">{{ $t("product_section_filter.price_range") }}</div>
      <div
        class="form-check cover filled big"
        v-for="(price_range, index) in filters.price_range"
        :key="index"
      >
        <input
          class="form-check-input"
          type="radio"
          name="price_range"
          v-bind:value="price_range.value"
          :id="'price_range_' + index"
          :checked="form_data.price_range == price_range.value"
          v-model="form_data.price_range"
        />
        <label class="form-check-label" :for="'price_range_' + index"
          >{{ price_range.label }}
        </label>
      </div>
    </div>

    <div class="filter">
      <div class="title">{{ $t("product_section_filter.radius") }}</div>
      <div
        class="form-check cover filled"
        v-for="(radius, index) in filters.radius"
        :key="index"
      >
        <input
          class="form-check-input"
          type="radio"
          name="radius"
          :value="radius.value"
          :id="'radius_' + index"
          :checked="form_data.radius == radius.value"
          v-model="form_data.radius"
        />
        <label class="form-check-label" :for="'radius_' + index"
          >{{ radius.label }}
        </label>
      </div>
    </div>

    <div class="filter pb-3">
      <div class="title">{{ $t("product_section_filter.price") }}</div>
      <div class="row">
        <div
          class="col-auto"
          v-for="(sort, index) in filters.sort_by_price"
          :key="index"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="sort_by_price"
              :value="sort.value"
              :id="'price_sort_' + index"
              :checked="form_data.sort_by_price == sort.value"
              v-model="form_data.sort_by_price"
            />
            <label class="form-check-label" :for="'price_sort_' + index"
              >{{ sort.label }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="filter pb-3">
      <div class="title">{{ $t("product_section_filter.sort_options") }}</div>
      <div
        class="form-check"
        v-for="(sort, index) in filters.sort_options"
        :key="index"
      >
        <input
          class="form-check-input"
          type="checkbox"
          name="sort_options[]"
          :value="sort.value"
          :id="'sort_' + index"
          v-model="form_data.sort"
        />
        <label class="form-check-label" :for="'sort_' + index"
          >{{ sort.label }}
        </label>
      </div>
    </div>

    <div class="filter pb-3">
      <div class="title">{{ $t("product_section_filter.payment_method") }}</div>
      <div
        class="form-check"
        v-for="(payment_method, index) in filters.payment_method"
        :key="index"
      >
        <input
          class="form-check-input"
          type="radio"
          name="payment_method"
          :value="payment_method.value"
          :id="'payment_method_' + index"
          :checked="form_data.payment_method == payment_method.value"
          v-model="form_data.payment_method"
        />
        <label class="form-check-label" :for="'payment_method_' + index">
          <img
            :src="
              require(`@/assets/payment_methods/${payment_method.value}.svg`)
            "
          />
          {{ payment_method.label }}
        </label>
      </div>
    </div>

    {{ filters.free_shipping[0].checked }}
    <div class="filter pb-3">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          :value="filters.free_shipping[0].value"
          id="free_shipping"
          :checked="filters.free_shipping[0].checked"
          name="free_shipping"
          v-model="form_data.free_shipping"
        />
        <label class="form-check-label" for="free_shipping"
          >{{ filters.free_shipping[0].label }}
        </label>
      </div>
    </div>

    <button class="btn btn-primary w-100" @click="saveFilters">Save</button>
  </div>
</template> 

<script>
export default {
  name: "ProductsSectionFilters",
  props: {
    defaults: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    //  'radius' => [5, 15, 50, 100, 250, 500]
    //  'price_range' => ['$', '$$', '$$$', '$$$$'],
    //  'shop_type_id' => id,
    //  'free_shipping' => [true | false],
    //  'payment_method' => ['both', 'cash', 'bank'],
    //  'sort_by' => ['price_asc', 'price_desc', 'rating', 'distance', 'delay'],
    return {
      form_data: {
        payment_method: "both",
        free_shipping: false,
        sort: [],
      },
      filters: {
        price_range: [
          {
            label: "$",
            value: "$",
          },
          {
            label: "$$",
            value: "$$",
          },
          {
            label: "$$$",
            value: "$$$",
          },
          {
            label: "$$$$",
            value: "$$$$",
          },
        ],
        radius: [
          {
            label: 5,
            value: 5,
          },
          {
            label: 15,
            value: 15,
          },
          {
            label: 50,
            value: 50,
          },
          {
            label: 100,
            value: 100,
          },
          {
            label: 250,
            value: 250,
          },
          {
            label: 500,
            value: 500,
          },
        ],
        sort_by_price: [
          {
            label: this.$t("product_section_filter.lowest_first"),
            value: "price_asc",
          },
          {
            label: this.$t("product_section_filter.highest_first"),
            value: "price_desc",
          },
        ],
        sort_options: [
          {
            label: this.$t("product_section_filter.rating"),
            value: "rating",
          },
          {
            label: this.$t("product_section_filter.distance"),
            value: "distance",
          },
          {
            label: this.$t("product_section_filter.preparing_time"),
            value: "delay",
          },
        ],
        payment_method: [
          {
            label: this.$t("shops.payment_methods.cash_and_bank"),
            value: "both",
          },
          {
            label: this.$t("shops.payment_methods.cash"),
            value: "cash",
          },
          {
            label: this.$t("shops.payment_methods.bank"),
            value: "bank",
          },
        ],
        free_shipping: [
          {
            label: this.$t("product_section_filter.free_shipping"),
            value: true,
          },
        ],
      },
    };
  },
  methods: {
    saveFilters: function () {
      this.$emit("saved", this.form_data);
    },
    generateDefaults(defaults) {
      if (defaults != null) {
        this.form_data = defaults;
      }
    },
  },
  created() {
    this.generateDefaults(this.defaults);
  },
  watch: {
    defaults: function (newDefaults) {
      this.generateDefaults(newDefaults);
    },
  },
};
</script>

<style lang='scss' scoped>
.filters {
  width: 100%;
  float: left;
  .filter {
    width: 100%;
    float: left;
    border-bottom: 1px solid map-get($defaults, "border-color");
    margin-bottom: map-get($spacings, "default");
    overflow: hidden;

    .title {
      font-weight: bold;
      margin-bottom: map-get($spacings, "xsmall");
    }

    .form-check {
      margin-right: map-get($spacings, "xsmall");
      margin-bottom: map-get($spacings, "xsmall");

      label {
        img {
          margin-top: -4px;
          height: 20px;
          margin-right: map-get($spacings, "xsmall");
        }
      }

      &.cover {
        &.filled {
          margin-bottom: map-get($spacings, "default");

          .form-check-input {
            & + label {
              padding: map-get($spacings, "xsmall") map-get($spacings, "small");
            }
          }

          &.big {
            .form-check-input {
              & + label {
                padding: map-get($spacings, "small")
                  map-get($spacings, "default");
              }
            }
          }
        }
      }
    }
  }
}
</style>