<template>
  <div class="filterView">
    <div class="body row g-0">
      <div class="col-auto">
        <div class="filtersWrapper" :class="{ shown: filtersShown }">
          <div class="filtersBody">
            <div class="header row justify-content-between">
              <div
                class="
                  col-3
                  Aligner
                  items-left
                  d-block d-md-none
                  order-3 order-md-1
                  close_column
                "
              >
                <button class="btn btn-clean close" @click="closeFilters">
                  <i class="fas fa-times-circle color-secondary"></i>
                </button>
              </div>
              <div
                class="
                  font-size-big font-weight-bold
                  col
                  Aligner
                  items-left
                  label_column
                  order-2
                "
              >
                {{ $t("global.filters") }}
              </div>
              <div
                class="
                  col-3
                  Aligner
                  items-right
                  order-1 order-md-3
                  reset_column
                "
              >
                <button
                  @click="resetFilters"
                  class="btn btn-link color-black hover-color-black px-0 btn-sm"
                >
                  {{ $t("global.reset") }}
                </button>
              </div>
            </div>
            <perfect-scrollbar :options="{ wheelPropagation: false }">
              <slot name="filters"></slot>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="products">
          <filter-seperator @openFilters="openFilters" />
          <slot name="body"></slot>
        </div>
      </div>
    </div>
    <!-- /.body -->
  </div>
</template>

<script>
import { ref } from "vue";
import FilterSeperator from "@/components/FilterSeperator.vue";
export default {
  setup() {
    const filters = ref(null);

    return {
      filters,
    };
  },
  components: { FilterSeperator },
  name: "FilterView",
  data() {
    return {
      filtersShown: false,
    };
  },
  methods: {
    openFilters() {
      this.filtersShown = true;
    },
    closeFilters() {
      this.filtersShown = false;
    },
    resetFilters() {
      this.$emit("resetFilters", true);
    },
  },
};
</script>

<style lang='scss' scoped>
.filterView {
  & > .body {
    position: relative;
    .filtersWrapper {
      width: 300px;
      min-height: 100%;
      .filtersBody {
        width: 100%;
        float: left;
        .header {
          padding-right: map-get($spacings, "seperator");
          padding-top: map-get($spacings, "seperator");
          padding-bottom: map-get($spacings, "default");
        }

        .ps {
          padding-bottom: map-get($spacings, "seperator");
          padding-right: map-get($spacings, "seperator");
        }
      }
    }

    .products {
      padding-top: map-get($spacings, "seperator");
      padding-left: map-get($spacings, "seperator");
      padding-bottom: map-get($spacings, "seperator");
      min-height: 100%;
      border-left: 1px solid map-get($defaults, "border-color");

      .filterSeperator {
        display: none;
        margin-bottom: map-get($spacings, "default");
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .filterView {
    & > .body {
      .filtersWrapper {
        display: none;
        overflow-y: hidden;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(map-get($colors, "black"), 0.8);
        z-index: 9999;
        padding: map-get($spacings, "default");

        &.shown {
          display: flex;
        }

        .filtersBody {
          background-color: #fff;
          padding: map-get($spacings, "default");
          border-radius: map-get($defaults, "border-radius");
          height: 100%;
          overflow: hidden;

          .header {
            padding-top: 0;
            padding-right: 0;

            .close_column {
              .close {
                float: right;
              }
            }

            .label_column {
              justify-content: center;
            }

            .reset_column {
              justify-content: flex-start;
            }
          }

          .ps {
            width: 100%;
            max-height: 100%;
            float: left;
            height: auto;
            padding-bottom: 0px;
            padding-right: 0;
            padding-bottom: 60px;
          }
        }
      }

      .products {
        padding-left: 0;
        padding-top: map-get($spacings, "default");
        border-left: 0;

        .filterSeperator {
          display: flex;
        }
      }
    }
  }
}
</style>
