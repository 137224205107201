<template>
  <div class="filterSeperator">
    <span class="label Ratio text-uppercase" @click="openFilters">
      <img src="@/assets/filter.svg" />
      {{ $t("global.filters") }}
    </span>
  </div>
  <!-- /.filterSeperator -->
</template>

<script>
export default {
  name: "FilterSeperator",
  methods: {
    openFilters() {
      this.$emit("openFilters", true);
    },
  },
};
</script>

<style lang='scss'>
.filterSeperator {
  outline: 1x solid red;
  width: 100%;
  float: left;
  clear: both;
  position: relative;
  display: flex;
  justify-content: flex-end;
  position: relative;
  border-top-left-radius: map-get($defaults, "border-radius");
  border-bottom-left-radius: map-get($defaults, "border-radius");

  &:before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: map-get($defaults, "border-color");
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .label {
    padding-left: map-get($spacings, "default");
    background-color: #fff;
    position: relative;
    z-index: 2;
    color: map-get($colors, "twiks");

    &:hover {
      cursor: pointer;
    }

    img {
      margin-top: -4px;
      margin-right: 5px;
    }
  }
}
</style>