<template>
  <div class="productCard">
    <router-link
      :to="'/products/' + product.id + '?title=' + product.name"
      class="color-black hover-color-black"
    >
      <div
        class="image ratio ratio-4_3"
        :style="
          'background-image: url(' +
          window.Routes.getImage(product.image, 'preview') +
          ');'
        "
      >
        <div class="body">
          <div class="discount" v-if="product.discount.discount_type != 'none'">
            - {{ product.discount.discount_value_formated }}
          </div>
          <div class="rating" v-if="product.rating != null">
            {{ product.rating }}
          </div>
        </div>
        <!-- /.body -->
      </div>

      <div class="body">
        <div
          class="title font-size-default font-weight-bold dotdotdot lines-1 expanded"
        >
          {{ product.name }}
        </div>
        <div class="delivery my-1 font-size-sm">
          {{ formated_delivery_price }}
          <span class="color-secondary"
            >&#9679; {{ product.delay }} {{ $t("products.min_preparing") }}
          </span>
        </div>
        <div class="price text-upperpcase">
          <span class="font-size-md font-weight-bold me-1">
            {{ formated_price }}
          </span>
          <span
            class="font-size-sm color-secondary text-decoration-line-through"
            v-if="product.discount.discount_type != 'none'"
          >
            {{ formated_original_price }}
          </span>
        </div>
        <!-- /.delivery -->
      </div>
    </router-link>
  </div>
</template>

<script>
import formatPrice from "@/includes/currency";
export default {
  name: "ProductCard",
  props: {
    product: Object,
  },
  computed: {
    formated_price: function () {
      let product_price = this.product.price;
      return formatPrice(this.product.currency, product_price);
    },
    formated_original_price: function () {
      let product_price =
        this.product.price + this.product.discount.discount_amount;
      return formatPrice(this.product.currency, product_price);
    },
    formated_delivery_price: function () {
      return this.product.delivery_price == 0
        ? " " + this.$t("products.free_shipping")
        : formatPrice(this.product.currency, this.product.delivery_price) +
            " " +
            this.$t("products.delivery");
    },
  },
};
</script>

<style lang="scss" scoped>
$rating_circle_size: 30px;
.productCard {
  a {
    display: block;
    float: left;
    width: 100%;
    & > .image {
      width: 100%;
      background-color: #fff;
      border-radius: map-get($defaults, "border-radius");
      background-position: center;
      background-size: cover;

      & > .body {
        .discount {
          padding: map-get($spacings, "xsmall");
          background-color: map-get($colors, "twiks");
          color: map-get($colors, "white");
          border-radius: map-get($defaults, "border-radius");
          position: absolute;
          top: map-get($spacings, "small");
          left: map-get($spacings, "small");
          width: auto;
          height: auto;
        }

        .rating {
          background-color: map-get($colors, "green");
          color: map-get($colors, "darkGreen");
          border-radius: $rating_circle_size;
          width: $rating_circle_size;
          height: $rating_circle_size;
          text-align: center;
          line-height: $rating_circle_size;
          position: absolute;
          bottom: map-get($spacings, "small");
          right: map-get($spacings, "small");
        }
      }
    }

    & > .body {
      width: 100%;
      padding: map-get($spacings, "default") 0;
    }
  }
}
</style>
